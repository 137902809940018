/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import axios from "axios";
import jwtDecode from "jwt-decode";
import Swal from "sweetalert2";
import { useNavigate, Link, useParams } from "react-router-dom";
import { FaSave, FaTimes, FaMinusCircle, FaCheck } from "react-icons/fa";
import { API_URL_AUTH } from "../../config/config";

const PetugasEdit = () => {
  /* eslint-disable-next-line */
  const [token, setToken] = useState("");
  const [expire, setExpire] = useState("");
  const [errors, setErrors] = useState([]);

  const { id } = useParams();
  const [namaLengkap, setNamaLengkap] = useState("");
  const [kesatuan, setKesatuan] = useState(0);

  const navigate = useNavigate();

  const itemKesatuan = [
    {
      name: "POLRESTABES BANDUNG",
      value: 1,
    },
    {
      name: "POLRES SUKABUMI",
      value: 2,
    },
    {
      name: "POLRES PURWAKARTA",
      value: 3,
    },
    {
      name: "POLRES BANJAR",
      value: 4,
    },
    {
      name: "POLRES KARAWANG",
      value: 5,
    },
    {
      name: "POLRES CIREBON",
      value: 6,
    },
    {
      name: "POLRES TASIKMALAYA",
      value: 7,
    },
    {
      name: "POLRES GARUT",
      value: 8,
    },
    {
      name: "POLRESTA CIREBON",
      value: 9,
    },
    {
      name: "POLRES INDRAMAYU",
      value: 10,
    },
    {
      name: "POLRES CIANJUR",
      value: 11,
    },
    {
      name: "POLRES SUMEDANG",
      value: 12,
    },
    {
      name: "POLRES BOGOR",
      value: 13,
    },
    {
      name: "POLRES KUNINGAN",
      value: 14,
    },
    {
      name: "POLRESTA BANDUNG",
      value: 15,
    },
    {
      name: "POLRESTA BOGOR",
      value: 16,
    },
    {
      name: "POLRES TASIKMALAYA",
      value: 17,
    },
    {
      name: "POLRES MAJALENGKA",
      value: 18,
    },
    {
      name: "POLRES SUBANG",
      value: 19,
    },
    {
      name: "POLRES PANGANDARAN",
      value: 20,
    },
    {
      name: "POLRES CIMAHI",
      value: 21,
    },
    {
      name: "POLRES CIAMIS",
      value: 22,
    },
    {
      name: "POLRES SUKABUMI",
      value: 23,
    },
  ];

  useEffect(() => {
    refreshToken();
    getPetugasById();
  }, []);

  const refreshToken = async () => {
    try {
      const response = await axios.get(`${API_URL_AUTH}/admin/refreshtoken`);
      setToken(response.data.token);
      const decoded = jwtDecode(response.data.token);
      setExpire(decoded.exp);
    } catch (error) {
      if (error.response) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
          timer: 2000,
          timerProgressBar: true,
          showConfirmButton: false,
          willClose: () => {
            navigate("/login");
          },
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            navigate("/login");
          }
        });
      }
    }
  };

  const getPetugasById = async () => {
    await axiosJWT
      .get(`${API_URL_AUTH}/admin/user-polres/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        setNamaLengkap(response.data.data.namaLengkap);
        setKesatuan(response.data.data.polresKode);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error.response.data.message,
        });
      });
  };

  const getNamaPolres = (kode) => {
    const result = itemKesatuan.find((item) => item.value === parseInt(kode));

    // console.log(result);
    return result.name;
  };

  const updatePetugas = async (e) => {
    e.preventDefault();

    await axiosJWT
      .put(
        `${API_URL_AUTH}/admin/user-polres/${id}`,
        {
          namaLengkap: namaLengkap,
          polresKode: parseInt(kesatuan),
          kesatuan: getNamaPolres(kesatuan),
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((response) => {
        setNamaLengkap("");
        setErrors([]);

        Swal.fire({
          title: "Sukses!",
          text: response.data.message,
          icon: "success",
          showCancelButton: true,
          confirmButtonText: "OK, Kembali ke menu!",
          cancelButtonText: "Tutup, Input data lagi!",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(-1);
          }
        });
      })
      .catch((error) => {
        if (error.response.status === 422) {
          console.log(error.response.data.errors);
          setErrors(error.response.data.errors);
          Swal.fire({
            icon: "warning",
            title: "Oops...",
            text: error.response.data.message,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: error.response.data.message,
          });
        }
      });
  };

  const axiosJWT = axios.create();

  axiosJWT.interceptors.request.use(
    async (config) => {
      const currentDate = new Date();
      if (expire * 1000 < currentDate.getTime()) {
        const response = await axios.get(`${API_URL_AUTH}/admin/refreshtoken`);
        config.headers.Authorization = `Bearer ${response.data.token}`;
        setToken(response.data.token);
        const decoded = jwtDecode(response.data.token);
        setExpire(decoded.exp);
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  const checkErrorInput = (errors, param) => {
    const found = errors.find((error) => error.param === param);
    if (found) {
      return found;
    } else {
      return false;
    }
  };

  return (
    <>
      <nav className="breadcrumb is-small" aria-label="breadcrumbs">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/petugas">Data Petugas Polres</Link>
          </li>
          <li className="is-active">
            <Link href="#" aria-current="page">
              Edit Petugas Polres
            </Link>
          </li>
        </ul>
      </nav>
      <div className="columns">
        <div className="column is-three-fifths">
          <form onSubmit={updatePetugas}>
            <div className="field is-horizontal">
              <div className="field-label is-small">
                <label className="label">Nama Lengkap</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <p className="control is-expanded has-icons-right">
                    <input
                      className="input is-small"
                      type="text"
                      value={namaLengkap}
                      onChange={(e) => setNamaLengkap(e.target.value)}
                      placeholder="Nama lengkap petugas"
                    />
                    {checkErrorInput(errors, "namaLengkap") ? (
                      <span className="icon is-small is-right has-text-danger">
                        <FaMinusCircle />
                      </span>
                    ) : (
                      <span className="icon is-small is-right">
                        <FaCheck />
                      </span>
                    )}
                  </p>
                  <p className="help">Nama lengkap petugas</p>
                </div>
              </div>
            </div>

            <div className="field is-horizontal">
              <div className="field-label is-small">
                <label className="label">Kesatuan</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <div className="control has-icons-right">
                    <select
                      value={kesatuan}
                      onChange={(e) => setKesatuan(e.target.value)}
                      className="select is-small is-fullwidth"
                    >
                      <option value="">- Pilih Kesatuan -</option>
                      {itemKesatuan.map((kesatuan, index) => (
                        <option key={`out-${index}`} value={kesatuan.value}>
                          {kesatuan.name}
                        </option>
                      ))}
                    </select>
                    {/* {kesatuan !== "" && getNamaPolres(kesatuan)} */}
                    {checkErrorInput(errors, "kesatuan") ? (
                      <span className="icon is-small is-right has-text-danger">
                        <FaMinusCircle />
                      </span>
                    ) : (
                      <span className="icon is-small is-right">
                        <FaCheck />
                      </span>
                    )}
                  </div>
                  <p className="help">Pilih outlet tempat bekerja petugas</p>
                </div>
                <div className="field">&nbsp;</div>
              </div>
            </div>

            <div className="field is-horizontal">
              <div className="field-label">&nbsp;</div>
              <div className="field-body">
                <div className="field is-grouped">
                  <div className="control">
                    <button
                      type="submit"
                      className="button is-link is-small is-rounded"
                    >
                      <span className="icon">
                        <FaSave />
                      </span>
                      <span>Simpan Petugas Polres</span>
                    </button>
                  </div>
                  <div className="control">
                    <button
                      type="button"
                      onClick={() => navigate(-1)}
                      className="button is-danger is-light is-small is-rounded"
                    >
                      <span className="icon">
                        <FaTimes />
                      </span>
                      <span>Batalkan</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <hr />
          {errors.map((error, index) => (
            <p key={`error-${index}`} className="has-text-danger is-size-7">
              <FaMinusCircle /> {error.msg}
            </p>
          ))}
        </div>
      </div>
    </>
  );
};

export default PetugasEdit;
